const MenuItem = [
    {
        key: "1",
        route: "/",
        name: "Home"
    },
    {
        key: "2",
        route: "/about",
        name: "About"
    },
    {
        key: "3",
        route: "/projects",
        name: "Projects"
    },
    {
        key: "4",
        route: "/contact",
        name: "Contact"
    },
];
export default MenuItem;
